table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  bottom: 0.5em;
}
table.dataTable thead th,
table.dataTable thead td {
  /* border: 1px solid rgba(0, 0, 0, 0.3) ; */
  border: 1px solid rgba(0, 0, 0, 0.15);
}
#dtBasicExample_length,
#dtBasicExample_filter {
  margin-bottom: 25px;
}
/* .form-switch .form-check-input {width: 3rem !important} */

.form-switch .form-check-input:after,
.form-switch .form-check-input:checked[type="checkbox"]:after {
  margin-top: -9px !important;
}
.form-switch {
  padding-left: 4.5rem !important;
}
.form-switch input {
  padding-left: 0px !important;
}
.dropdown-toggle {
  padding: 0.625rem 1rem 0.5rem 0.5rem !important;
}
/* new css*/

tr {
  vertical-align: middle;
  text-align: center;
  font-family: Helvetica, sans-serif;
  font-size: 16px;
}
.select {
  float: right;
  padding: 0.625rem 1rem 0.5rem 0.5rem !important;
}
.fa-edit,
.fa-trash {
  font-size: 20px !important;
}

.a1 {
  color: #ff5722;
  /* position: relative;
  top: 4rem; */
  /* margin-top: "3rem"; */
}

.selectBtn {
  color: #000;
  background: #fff;
  border: none;
  /* position: relative;
  left: 7vw; */
}
select,
option {
  border: none;
}
.worklog_icon {
  color: #333;
  background-color: white;
  font-size: 24px;
}
.worklog_icon:hover {
  color: #222;
  transform: scale(1.2);
}
.table_content {
  background-color: #444;
}
@media screen and (max-width: 786px) {
  .table {
    flex-wrap: wrap;
  }
  .table .col {
    width: 50%;
  }
}
.inp {
  margin-left: 24px;
}
.toggle-off,
.toggle-on {
  color: dodgerblue;
}
.toggle-off:hover,
.toggle-on:hover {
  color: dodgerblue;
  cursor: pointer;
}
.deleteIcon {
  cursor: pointer;
}

.addUserSvg {
  width: 2rem;
  height: 2rem;
  stroke: #ff5722 !important;
}
.addBtn:hover {
  transform: scale(1.03);
  background-color: #444;
  color: #fff;
}

.addBtn {
  background-color: #444;
  border-radius: 25rem !important;

  /* background-color: #333; */
  /* color: #fff; */
}

.bench_list {
    background-color: #ff5722;
    color: #fbfbfb;
    font-weight: 600;
    padding: 6px 12px;
    border: none;
    outline: none;
    cursor: crosshair;
    width: 12%;
    border-radius: 6px;
    margin-top: 10px;
  }
  .bench_list:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }