.employeeFieldsInputs{
    border-radius: 0;
    border-bottom: 1px solid lightgrey !important;
}

.employeeFieldsTitle{
    text-align: center;
}








/* form starting stylings ------------------------------- */
.group {
    position: relative;
    margin-bottom: 45px;
  }
  input {
    background-color: #fbfbfb;
    padding: 10px 10px 10px 5px;
    display: block;
    border: none;
    border-bottom: 1px solid #757575;
  }
  input:focus {
    outline: none;
  }
  
  /* LABEL ======================================= */
  label {
    color: #999;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  
  /* active state */
  input:focus ~ label,
  input:valid ~ label {
    top: -20px;
    font-size: 14px;
  }
  

.vh-100{
  height: 90vh !important;
}