.employeeFieldsInputs {
  border-radius: 0;
  border-bottom: 1px solid lightgrey !important;
}

.employeeFieldsTitle {
  text-align: center;
}

/* form starting stylings ------------------------------- */
.group {
  position: relative;
  margin-bottom: 45px;
}
input {
  background-color: #fbfbfb;
  padding: 10px 10px 10px 5px;
  display: block;
  border: none;
  border-bottom: 1px solid #757575;
}
input:focus {
  outline: none;
}

/* LABEL ======================================= */
label {
  color: #999;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
input:focus ~ label,
input:valid ~ label {
  top: -20px;
  font-size: 14px;
}
.employeeFieldCard {
  position: relative;
  height: 200px;
}
.employeeFieldInput {
  width: 100%;
}

.employeeFieldsAddButton {
  margin-right: 30px;
}
.selectBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 100%;
  border: 2px solid #999;
  border-radius: 5px;
}

.input-boxes {
  height: 38px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 5px;
  color: #333;
}
.has-Error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  border: rgb(240, 14, 14) 2px solid;
  outline: none;
  border-radius: 5px;
}
.initial-input {
  display: none;
}
