/* .gradient-custom-2 {
  /* fallback for old browsers */
  /* background: #fccb90; */

  /* Chrome 10-25, Safari 5.1-6 */
  /* background: -webkit-linear-gradient(
    to right,
    #ee7724,
    #d8363a,
    #dd3675,
    #b44593
  ); */

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  /* background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593); */
/* }  */
.component{
  box-shadow: 8px 8px 4px rgb(237, 232, 232);

}
.signIn{
  background: #ee7724;
  /* background: linear-gradient(to right,#FFB648, #FF5D5D,); */
}

.container-fluid{
  background-color: #f4f4f4  ;
}
.siBtn{
  color: #ee7724;
  position: relative;
  margin-left: 6.2em;
  background: #000;
  border-radius: 0.7rem;
  height: 2.5rem;
  font-size: 15px;
}
.head-2{
  color: #ee7724;
  font-weight: bold;

}
.separator{
  display: flex;
  align-items: center;
}

.separator p{
  padding: 0 1rem; /* creates the space */
}

.separator .line{
  flex: 1;
  height: 3px;
  background-color: #666;
}

/* .signIn-1{ */
  /* color: #fff !important; */
  /* font-weight: 600; */
  /* font-size:  3rem;; */
/* } */

/* @media (min-width: 768px) {
    .gradient-form {
    height: 100vh !important;
    }
    } */
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
  }
}
.google{
height: 1.5rem;
width: 1.5rem;
}
.logoImg {
  width: 320px;
}
.form-control {
  border: none;
  background-color: #fff;
}
#floatingInputCustom {
  height: 40px;
  background-color: #fff;
}
textarea {
  background-color: #fff;
}
.form-control:focus,.gBtn {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #fff;
  font-size: 15px;
}
.svgIcon{
  height: 1rem;
  width: 1rem;
}
/* .gBtn{
  border: none;
} */

.mt-3{
  margin-top: 0.7rem !important;
}