.personBench {
  height: 75%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.responsive {
  max-height: 296px;
  /* border-radius: 1.6rem; */
}

.table {
  /* border: 0px  solid ; */
  /* padding: 10px; */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
}
.activeTable {
  height: 48vh;
  /* margin-bottom: 5rem; */
  overflow-y: auto;
  border-radius: 10px;
}

.cardsHead {
  color: #120f1a;
}

.h3-subHead {
  color: #ff5722;
  /* display:inline-flex; */
  padding: 0;
  /* background: #444 !important; */
  /* display: inline-block; */



}

.adminDashboardTableHead {
  width: 100%;
  background-color: #120f1a;
  color: #fff;
  padding-left: 10px;
  border-radius: 20px;
}

.table-headings {
  /* color: #ff5722;

  
   */
  color: #fff;
  /* align-items: center; */
}

/* color: #fff; */
/* align-items: center; */

/* CSS  */
.headings_blocks {
  border-radius: 8px;
}
.bench {
  color: #fff !important;
}

.poc-p {
  /* background: #fff !important; */
  background: #fff !important;
}

/* .poc { */
/* color: #231955 !important; */
/* ) */

.btns {
  font-size: x-large;
}
.poc-p {
  /* background: #fff !important; */
  background: #444 !important;
  /* border-radius: 5px !important; */
}

.poc {
  color: #fff !important;
}

.button-56 {
  padding: 0px;
  align-items: initial;
  width: 200px;

  /* background: #fff; */
  /* background: linear-gradient(to right, #3d3335, #db24db); */
  /* background: linear-gradient(to right,#fff, #E8630A); */
  background: #ff5722;

  /* border-radius: 8px; */
  border: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Inter, sans-serif;
  font-size: 16px;
  /* height: 48px; */
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  /* padding: 0 25px; */
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.button-56 > h1 {
  font-weight: normal;
  color: #120f1a;
  /* color: green; */
}

.button-56:after {
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: transform 0.2s ease-out;
  z-index: -1;
}
.card {
  /* overflow: auto; 
  */
  overflow-wrap: break-word;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  outline: 0;
  /* background-color: #120f1a; */
}

.button-56:hover {
  outline: 0;
}
.liElement {
  list-style: none;
}

/* .thName { */

/* .thName{
  position: relative;
  left: -3rem;
} */

/* align-items:inherit; */

/* background: linear-gradient(to right, #3d3335, #db24db); */

/* .tbody{ */
/* text-align: center; */
/* } */

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}

.badge {
  float: none;
  border-radius: 1.25rem !important;
}
.button1:focus {
  background-color: red;
}

/* CaRDS */
.card-body-top {
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.card-body-top:hover {
  cursor: pointer;
  transform: translateY(-1px);
}
.card-body-bottom {
  border: 1px solid;
  border-color: #e7e6e6;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.card-body-bottom:hover {
  cursor: pointer;
  transform: translateY(-1px);
  transform: scale(1.02);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.card-text {
  color: #ff5722;
}

.card-1 {
  color: #ff1e00;
}
.card-2 {
  color: #f8f83b;
}
.card-3 {
  color: #2b7a0b;
}
.card-3 {
  color: #2b7a0b;
}
.allBtn {
  border-radius: 10px;
  padding: 3pox !important;
}
.allP {
  margin-top: 15px;
}

/* @media screen (max-width: 1024px) {
  .card-1{

  }
  
} */
/* @media (min-width: 1024px) {
  .piechart {
    margin-top: 7rem;
  }
} */
/* @media (max-width: 1440px) {
  .piechart {
    margin-top: 0rem;
    /* margin:3rem; */
/* margin-top: 0.5rem; 
  }
} */
@media only screen and (min-width: 992px) and (max-width: 1192px) {
  .piechart {
    margin-top: 7rem;
  }
}

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .personBench {
    margin-left: 3rem;
  }
}
/* Card modal  */
.cardModal{
/* width: 60rem; */
/* height: 40rem; */
  background:transparent;
  /* opacity: -1; */

}

.modal-backdrop
{
    opacity:0.5 !important;
}

.ModaHeadTitle{
  font-weight: 600;
  font-size: 130%;
}

/* .table-fixed tbody {
  /* height: 100px; */
  /* overflow-x: scroll; */
  /* width: 100%; */
/* } */
