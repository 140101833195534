.worklogTitle {
    background-color: #ff5722;
    color: #fbfbfb;
    font-weight: 600;
    padding: 6px 12px;
    border: none;
    outline: none;
    cursor: crosshair;
    width: 12%;
    border-radius: 6px;
  }
  .worklogTitle:hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }
  .table_content {
    background-color: #444;
  }
  /* tr > td{
    border:1px solid #444;
  } */