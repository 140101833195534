.workLogsTitle{
    text-align: center;
}

.WorkLogsTable{
    width: initial;
    margin: auto;

}


.WorkLogsTable>thead{
vertical-align: middle !important;
}